var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "warning", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "white--text text-body-1 font-weight-bold mb-2" },
        [_vm._v(" " + _vm._s(_vm.$t("checkout.completeProfileTitle")) + " ")]
      ),
      _c("v-card-subtitle", { staticClass: "white--text text-body-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("checkout.completeProfileSubTitle")) + " ")
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "white default--text",
              attrs: {
                outlined: "",
                text: "",
                to: "/profile/profile-update",
                block: ""
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("checkout.completeProfileTitle")) + " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }