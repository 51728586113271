var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "checkout",
      class: { "w-90": _vm.$vuetify.breakpoint.lgAndUp }
    },
    [
      _c(
        "div",
        { staticClass: "checkout-title" },
        [
          _c("CategoryTitle", {
            attrs: {
              category: _vm.category,
              showOnMobile: true,
              centered: _vm.$vuetify.breakpoint.xs,
              showDescription: true
            }
          })
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("ResponseMessage", { attrs: { response: _vm.response } })],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  !_vm.cart.user.profile.confirmed
                    ? _c("CheckoutConfirmEmail", {
                        key: _vm.cart.refreshTime,
                        on: { sendEmail: _vm.sendActivationMail }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0",
                  attrs: {
                    cols: "12",
                    md: "9",
                    lg: "8",
                    order: _vm.$vuetify.breakpoint.smAndDown ? "last" : 0
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-3 px-3 px-sm-0",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 px-md-3",
                          attrs: { cols: "12", order: "4" }
                        },
                        [
                          _c("CartItemList", {
                            staticClass: "items-list",
                            attrs: {
                              isCheckout: true,
                              full: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm.giftCertificates.length > 0
                            ? _c("gift-list", {
                                attrs: {
                                  mode: "checkout",
                                  title: _vm.$t("checkout.giftCodeSubtitle"),
                                  giftList: _vm.giftCertificates
                                },
                                on: { reload: _vm.loadGiftCertificates }
                              })
                            : _vm._e(),
                          _vm.error
                            ? _c(
                                "v-alert",
                                {
                                  attrs: {
                                    type: "error",
                                    border: "left",
                                    transition: "v-fab-transition"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.error) + " ")]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "checkout-note py-3" }, [
                            _vm._v(_vm._s(_vm.$t("checkout.note")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", order: "5" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkout-actions d-flex justify-space-between align-center flex-column flex-md-row"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column flex-md-row mb-2 mb-md-0 w-100 w-sm-auto justify-space-between justify-md-start"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        color: "secondary",
                                        large: "",
                                        text: "",
                                        depressed: ""
                                      },
                                      on: { click: _vm.emptyCartConfirm }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("checkout.emptyCartBtn")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column flex-md-row justify-space-between justify-md-end w-100 w-sm-auto"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "mr-0 mr-md-2 mb-2 mb-md-0 text-uppercase",
                                      attrs: {
                                        color: "secondary",
                                        large: "",
                                        depressed: "",
                                        outlined: ""
                                      },
                                      on: { click: _vm.addAllToFavorites }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("checkout.addToListBtn")
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        color: "primary",
                                        large: "",
                                        depressed: "",
                                        disabled:
                                          _vm.profileLevel <
                                            _vm.minProfileLevel ||
                                          _vm.cart.totalItems == 0,
                                        loading: _vm.loading
                                      },
                                      on: { click: _vm.goToPayment }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("checkout.goToPaymentBtn")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("category-block", {
                            staticClass: "category-block category-block-2",
                            attrs: {
                              target: _vm.category,
                              container: false,
                              position: "position2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0",
                  attrs: { cols: "12", md: "3", lg: "4" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-3 px-3 px-sm-0" },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c("CartInfoAddressCard", {
                            staticClass: "w-100",
                            attrs: {
                              shippingAddress: _vm.cart.shippingAddress,
                              editable: _vm.editableAddress,
                              isCheckout: true
                            },
                            on: { edit: _vm.openAddressSelector }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("CartInfoTimeslotCard", {
                            staticClass: "w-100 cart-info-timeslot-card",
                            attrs: {
                              shippingAddress: _vm.cart.shippingAddress,
                              timeslot: _vm.cart.timeslot,
                              editable: _vm.editableTimeslot,
                              isCheckout: true,
                              value: _vm.timeslot
                            },
                            on: { edit: _vm.openTimeslotSelector }
                          })
                        ],
                        1
                      ),
                      _vm.profileLevel < _vm.minProfileLevel
                        ? _c("CheckoutCompleteProfile", { staticClass: "mb-5" })
                        : _vm._e(),
                      _c("CheckoutSummary", {
                        staticClass: "mt-3",
                        attrs: { orderCart: _vm.cart },
                        on: { removeGiftCode: _vm.loadGiftCertificates }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-5 w-100 d-none d-md-flex",
                          attrs: {
                            color: "primary",
                            large: "",
                            block: _vm.$vuetify.breakpoint.xs,
                            depressed: "",
                            disabled:
                              _vm.profileLevel < _vm.minProfileLevel ||
                              _vm.cart.totalItems == 0,
                            loading: _vm.loading
                          },
                          on: { click: _vm.goToPayment }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("checkout.goToPaymentBtn")))
                          ])
                        ]
                      ),
                      _c("category-block", {
                        staticClass: "category-block category-block-3",
                        attrs: {
                          target: _vm.category,
                          container: false,
                          position: "position3"
                        }
                      }),
                      _c("category-block", {
                        staticClass: "category-block category-block-4",
                        attrs: {
                          target: _vm.category,
                          container: false,
                          position: "position4"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }