var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-complete-profile warning", attrs: { flat: "" } },
    [
      _c(
        "v-card-subtitle",
        { staticClass: "text-body-1 white--text" },
        [
          _c("i18n", {
            attrs: { path: "checkout.confirmEmailSubTitle", tag: "div" },
            scopedSlots: _vm._u([
              {
                key: "link",
                fn: function() {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "white--text text-decoration-underline",
                                    on: { click: _vm.sendActivationMail }
                                  },
                                  [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "checkout.confirmEmailLink"
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("checkout.confirmEmailTooltip")))
                        ])
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }